<template>
  <div id="app" :class="{ cdp: theUser && theUser.isCdpEnabled }">
    <CampaignNotesSheet/>
    <AccountNotesSheet/>
    <LoginModal ref="dialogueLogin"></LoginModal>
    <SwitchAccountModal ref="dialogueSwitchAccount"></SwitchAccountModal>
    <ImageLibraryModal ref="dialogueImageLibrary"></ImageLibraryModal>

    <div v-if="!siteLayout && !printLayout" id="MainWrap">
      <template v-if="loading">
        <div id="MainLoading">
          <div id="MainLoading_indicator"></div>
          <div id="MainLoading_text">Loading...</div>
        </div>
      </template>
      <template v-else>
        <app-frame
          class="AppFrame"
          :sidebar-min="sidebarMin"
          :layoutMode="theUser.isCdpEnabled ? 'alternate' : 'default'"
          :theme="theUser.isCdpEnabled ? 'pink' : 'default'"
        >
          <template #topbar>
            <top-nav
              :title="title"
              :title-click="titleClick"
              :resources="resources"
              :mobile="mobile"
              @update-mobile="updateMobile"
            />
          </template>
          <template #sidebar>
            <navigation
              v-if="navigation && navigation.length"
              :sections="navigation || []"
              :theme="theUser.isCdpEnabled ? 'dark' : 'light'"
              :allowMinimize="theUser.isCdpEnabled ? true : false"
              :logo="theUser.isCdpEnabled ? true : false"
              @toggle-min="(min) => sidebarMin = min"
              @clickLogo="theUser.isCdpEnabled ? $router.push({ name: 'CdpLanding' }) : null"
            />
          </template>
          <router-view></router-view>
          <template #footer v-if="!mobile && !sv">
            <footer>
              <div class="copyright">&copy; Fanplayr Inc. {{ dayjs().format('YYYY') }}</div>
              <div v-if="theUser" class="timezone">{{$t('Account Time')}}: <Formatter type="dateTimeShort" :value="accountTime"/></div>
              <div class="links">
                <a href="http://www.fanplayr.com/terms-conditions/">{{ $t('Terms & Conditions') }}</a> | <a href="http://www.fanplayr.com/terms-conditions/">{{ $t('Privacy Policy') }}</a>
              </div>
            </footer>
          </template>
        </app-frame>
      </template>
    </div>

    <div v-else-if="printLayout">
      <router-view></router-view>
    </div>

    <div v-else id="SiteWrap">
      <site-top-nav></site-top-nav>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

import TopNav from '@/components/TopNav'
import SiteTopNav from '@/components/SiteTopNav'

import CampaignNotesSheet from '@/components/dialogue/CampaignNotesSheet'
import AccountNotesSheet from '@/components/dialogue/AccountNotesSheet'
import LoginModal from '@/components/dialogue/LoginModal.vue';
import SwitchAccountModal from '@/components/dialogue/SwitchAccountModal.vue';
import ImageLibraryModal from '@/components/dialogue/ImageLibraryModal.vue';
import { dayjs, mysqlFormat } from '../src/classes/utils/DateUtil'

import posthog from 'posthog-js'

export default {
  name: 'app',
  metaInfo: {
    title: 'Portal',
    titleTemplate: '%s | Fanplayr'
  },
  data() {
    return {
      loading: true,
      siteLayout: false,
      printLayout: false,
      dayjs,
      loadingInt: null,
      clockIntervalHandle: null,
      accountTime: null,
      toWarnAutoLogout: null,
      toAutoLogout: null,
      autoLogoutMinutes: null,

      mobile: false,

      //
      navigation: null,
      title: '',
      titleClick: null,
      resources: '',

      sidebarMin: false,
    }
  },
  async mounted() {

    posthog.init('phc_9nj2VqnljUvvbZ6kJnbuvSfUtKlMTeDksl7O7IdKiKQ', {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only',
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        }
      }
    })
    

    this.eventHub.$on('setNavigation', (navigation) => {
      this.navigation = navigation
    })
    this.eventHub.$on('setTitle', (title, titleClick) => {
      this.title = title
      if (!titleClick) {
        this.titleClick = null
      } else if (titleClick instanceof Function) {
        this.titleClick = titleClick
      } else {
        this.titleClick = () => {
          this.$router.push({name: titleClick})
          .catch(this.duplicateRouteCatch)
        }
      }
    })
    this.eventHub.$on('setResources', (resources) => {
      this.resources = resources
    })

    const el = window.document.getElementById('MainLoading_indicator')
    let loadingPercent = 0
    const fastLoadingPrecent = 10
    this.loadingInt = setInterval(() => {
      if (loadingPercent < fastLoadingPrecent) {
        loadingPercent++
      } else if (loadingPercent < 50) {
        loadingPercent += (100 - loadingPercent) / fastLoadingPrecent / 5
      } else {
        loadingPercent += (100 - loadingPercent) / fastLoadingPrecent / 30
      }
      el.style.right = `${100 - loadingPercent}%`
    })

    // add global dialogue access
    this.$dialogue = {
      login: this.$refs.dialogueLogin.show,
      switchAccount: this.$refs.dialogueSwitchAccount.show,
      imageLibrary: this.$refs.dialogueImageLibrary.show,
    }

    this.$router.beforeResolve((to, from, next) => {
      // sets mobile var if routing to quick
      this.mobile = to.path.indexOf('/quick') === 0
      next()
    })

    this.siteLayout = this.$route.path.indexOf('/site/') === 0 || this.$route.path.indexOf('/redirectLogin/') === 0
    this.printLayout = this.$route.path.indexOf('/print/') === 0

    this.$router.beforeEach(async (to, from, next) => {
      this.siteLayout = to.path.indexOf('/site/') === 0 || to.path.indexOf('/redirectLogin/') === 0
      this.printLayout = to.path.indexOf('/print/') === 0
      this.previouslySiteLayout = from.path.indexOf('/site/') === 0 || from.path.indexOf('/redirectLogin/') === 0
      const quickLayout = to.path.indexOf('/quick/') === 0 || to.path.indexOf('/logout/') === 0

      // guard access to site
      if (
        !this.siteLayout &&
        !this.printLayout && 
        this.previouslySiteLayout &&
        !quickLayout &&
        this.onMobileDevice &&
        this.theUser.isSuper
      ) {
        const goMobile = await this.$ui.confirm({
          title: this.$t('nav.quickMobilemode'),
          content: this.$t('nav.goToQuickMobileMode')
        })
        if (goMobile) {
          next({ name: 'MobileLayout' })
          return
        }
      }

      // console.log('App', to.path)
      
      if (to.path.indexOf('/campaigns/') === 0 && !this.theUser.hasCampaignAccess) {
        next({ name: 'Insights' })
      } else if (to.path.indexOf('/notifications/') === 0 && !this.theUser.webPushEnabled) {
        next({ name: 'Campaigns' })
      } else {

        // came from site, but now not on /site/ (or on print layout page)
        // should only happen on login
        if (!this.printLayout) {
          if (!this.siteLayout && this.previouslySiteLayout) {
            this.checkForAutoLogout()
            this.checkForValid2fa()
            this.checkForPasswordChangeNeeded()
          }
        }
        next()
      }
    })

    // should only happens on refresh of page
    if (!this.siteLayout && !this.printLayout) {
      try {
        await this.doRefreshToken()
        this.checkForAutoLogout()
      } catch (e) {
        this.$router.push(`/site/login/?next=${encodeURIComponent(this.$route.path)}`)
        this.appMountComplete = true
        this.loading = false
        clearInterval(this.loadingInt)
        return
        // await this.doLogin()
      }
    }

    this.clockIntervalHandle = setInterval(this.updateTime, 1 * 1000)

    this.appMountComplete = true
    this.loading = false
  },
  beforeDestroy() {
    clearInterval(this.clockIntervalHandle)
    this.clearAutoLogoutListeners()
  },
  computed: {
    sv() {
      return this.$route.path.indexOf('/sessionViewer/') === 0
    }
  },
  methods: {
    checkForPasswordChangeNeeded() {
      this.api.getSecurityPasswordChangeRequired()
      .then(needsToChoose => {
        if (needsToChoose) {
          this.$router.push('/account/changePassword/')
          this.$ui.alert({
            title: this.$t('Change Password'),
            content: this.$t('You are required to change your password')
          })
        }
      })
      .catch(this.apiCatch)
    },
    checkForValid2fa() {
      this.api.getSecurity2faChoiceRequired()
      .then(needsToChoose => {
        if (needsToChoose) {
          this.$router.push('/account/profile/')
          this.$ui.alert({
            title: this.$t('Two Factor Authentication (2FA)'),
            content: this.$t('Please set your Preferred 2FA Method')
          })
        }
      })
      .catch(this.apiCatch)
    },
    async checkForAutoLogout() {
      this.clearAutoLogoutListeners()
      this.api.getSecurityAutoLogout()
      .then(res => {
        if (res && res.numMinutes > 0) {
          this.autoLogoutMinutes = res.numMinutes
          // start watchers
          window.document.addEventListener('keydown', this.onAnyInput)
          window.document.addEventListener('mousemove', this.onAnyInput)
          window.document.addEventListener('click', this.onAnyInput)
          //
          this.startAutoLogoutTo()
        }
      })
      .catch(this.apiCatch)
    },
    onAnyInput() {
      clearTimeout(this.toWarnAutoLogout)
      clearTimeout(this.toAutoLogout)
      this.startAutoLogoutTo()
    },
    startAutoLogoutTo() {
      this.toWarnAutoLogout = setTimeout(() => {
        this.$ui.alert({
          title: this.$t('Logout?'),
          content: this.$t('You will be logged out soon due to inactivity'),
          primaryActionLabel: this.$t('Cancel')
        })
      }, this.autoLogoutMinutes * 60 * 1000 - 30 * 1000)

      this.toAutoLogout = setTimeout(async () => {
        this.clearAutoLogoutListeners()
        await this.api.logout()
        this.$router.push('/site/login/')
      }, this.autoLogoutMinutes * 60 * 1000)
    },
    clearAutoLogoutListeners() {
      // clear any listeners used for auto logout
      window.document.removeEventListener('keydown', this.onAnyInput)
      window.document.removeEventListener('mousemove', this.onAnyInput)
      window.document.removeEventListener('click', this.onAnyInput)
    },
    updateTime() {
      if (this.theUser && this.theUser.accountTimezone) {
        this.accountTime = dayjs().tz(this.theUser.accountTimezone).format(mysqlFormat)
      }
    },
    updateMobile(mobile) {
      this.$router.push(mobile ? '/quick' : '/campaigns')
    }
  },
  components: {
    SiteTopNav,
    TopNav,
    LoginModal,
    SwitchAccountModal,
    ImageLibraryModal,
    CampaignNotesSheet,
    AccountNotesSheet
  }
}
</script>

<style lang="scss">

// RESET instead of bootstrap
* {
  box-sizing: border-box;
}
body, html {
  padding: 0px;
  margin: 0px;
  height: 100%;
  font-size: 14px;
}
body {
  // display: flex;
  // flex-direction: column;
  font-family: 'Roboto', sans-serif;
}

:after, :before {
  box-sizing: border-box;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
.table {
  width: 100%;
  border-collapse: collapse;
  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
  }
  &.table td, &.table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  &.table-bordered td, &.table-bordered th {
    border: 1px solid #dee2e6;
  }
}
img {
  vertical-align: middle;
  border: 0;
}

.thumbnail {
  div {
    word-break: break-word;
  }
}

strong {
  font-weight: bold;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

ul {
  margin-bottom: 0;
}

.clearfix {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
// /-RESET

.link, .UIElement[class*='Link_Link_'] {
  color: #006fbb !important;
  text-decoration: none !important;
  &:hover {
    color: #084e8a !important;
    text-decoration: underline !important;
    cursor: pointer !important;
  }
}

.ErrorText {
  display: flex;
  // align-items: center;
  i {
    margin-right: 15px;
    color: #ccc;
  }
  div {
    flex: 1;
    h3 {
      margin-bottom: 5px;
    }
    ul ul {
      list-style-type: square;
      padding-left: 25px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.s-formRequired .required label {
  color: #dc3545;
  &::before {
    content: '! '
  }
}

.fa-plus-circle {
  color: #5CB85C;
}

.fa-minus-circle {
  color: #FD7A71;
}

// hide recaptcha badge
.grecaptcha-badge{
	visibility: collapse !important;
}

// #app {
//   display: flex;
//   flex-direction: column;
//   height: 100%;
// }

.AppFrame {
  position: absolute;
  inset: 0;
}

#MainLoading {
  // background: #fff;
  background: #424242;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  #MainLoading_indicator {
    background: #F37C37;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
  }
  #MainLoading_text {
    width: 100%;
    color: #fff;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 44px;
    font-weight: 100;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
}

#MainWrap, #SiteWrap {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  // margin-top: 16px;
  .Main-Inner {
    display: flex;
    flex: 1;
    // background-color: #f6f6f6;
    // background: #eee;
    .Main-Sidebar-Wrapper {
      flex: 0;
      min-width: 250px;
      // position: sticky;
      // top: 0;
      background-color: #363c4b;
      color: #fff;
      margin-left: -5px;
      margin-top: 0px;
      padding-top: 6px;
      padding-bottom: 100px;

      @media screen and (max-width: 1000px) {
        min-width: 200px;
        margin-top: 0px;
        margin-left: 0px;
      }

      @media screen and (max-width: 850px) {
        min-width: 150px;
      }
      .Main-Sidebar {
        position: sticky;
        top: 75px;
        width: 250px;

        @media screen and (max-height: 900px) {
          top: 35px;
        }

        @media screen and (max-width: 1000px) {
          width: 190px;
        }

        @media screen and (max-width: 850px) {
          width: 150px;
        }

        .nav {
          font-size: 18px;
          font-weight: 300;
          margin-left: 15px;
          margin-right: 15px;
          padding-right: 25px;

          @media screen and (max-width: 1000px) {
            font-size: 15px;
            margin-left: 5px;
            margin-right: 5px;
          }

          @media screen and (max-width: 850px) {
            font-size: 13px;
          }

          .section {
            border-bottom: 1px solid #ccc;
            background: none;
            padding: 10px 0px 5px 0px;
            margin-bottom: 5px;
            margin-top: 5px;

            color: #fff;
            font-size: 18px;
            font-weight: 400;
            margin-left: 11px;
            margin-right: 14px;

            @media screen and (max-width: 1000px) {
              font-size: 13px;
            }

            &.section-top {
              margin-top: 0px;
            }
          }
          li {
            width: 100%;
            padding: 7px 0px;
            margin-left: 11px;
            margin-right: 14px;
            display: block;

            @media screen and (max-height: 900px) {
              padding: 3px;
              font-size: 16px;
            }
            &.off {
              opacity: .5;
              a {
                cursor: default;
                //border-left: 11px solid transparent;
              }
            }
            .nav-link {
              cursor: pointer;
            }
            .item-count {
              float: right;
            }
            a {
              color: #fff;
              border-bottom: 2px solid #363c4b;
              font-weight: 100;
              padding: 0px;
              margin-left: 0px;
              display: block;

              &:focus {
                background: transparent;
              }
              &:hover {
                background: #363c4b;
                border-bottom: 2px solid #fff;
              }
            }
            &.active a {
              color: #F37C37;
              font-weight: 400;
            }
          }
        }
      }
    }
    .Main-Content {
      flex: 1;
      // background-color: #eee;
      // padding-left: 25px;
      // padding-right: 25px;
      overflow: hidden;
      h1 {
        font-size: 160%;
        color: #6e6e6e;
        margin: 0;
        font-weight: normal;
        height: 44px;
        font-weight: 300;
        font-size: 160%;
        // padding: 20px 31px;

        &.section-header {
          .section-header-text {
            display: inline-block;
          }
          .section-header-input {
            display: inline-block;
            margin-left: 15px;
            // float: right;
            input {
              width: 180px;
            }
          }
        }
      }
      .Content-Box {
        //border: 1px solid #e5e5e5;
        box-shadow: 0 1px 2px rgba(0,0,0,.1);
        border-radius: 1px;
        background-color: #fff;
        margin: 30px;

        &.actions {
          padding: 20px;
          margin-top: -20px;
          // clearfix
          &::after {
            display: block;
            clear: both;
            content: "";
          }
        }

        &.below-hint {
          margin-top: 30px;
        }

        .section {
          padding: 20px;
          background: #fff;
        }

        h1 {
          height: 44px;
          padding: 12px;
          font-weight: 300;
          background: #fff;
          font-size: 25px;
          margin-bottom: 0;
          padding-bottom: 0;
          padding-top: 21px;
          margin-left: 8px;
          border-bottom: 0;
          color: #000;
        }
      }
    }
  }
}

.hint-block {
  background-color: #ddf4fb;
  border: 1px solid #c6edf9;
  padding: 15px;
  margin: 15px 30px;
  h2 {
    margin: 0;
    font-size: 20px;
    margin-bottom: 15px;
  }
}

.Content-Box {
  .hint-block {
    margin: 0 0 15px 0;
  }
}

.btn-full-width {
  width: 100%;
}

#SiteWrap {
  .Content-Box {
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #e5e5e5;
    padding: 15px 15px 30px 15px;
    max-width: 460px !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

footer {
  flex-shrink: 0;
  height: 45px;
  padding: 15px;
  // background: #fff;
  border-top: 1px solid #eee;
  color: #666;
  display: flex !important; // overriding bootstrap from insights
  line-height: normal !important; // overriding bootstrap from insights
  .copyright {
    flex: 1 1 auto;
  }
  .timezone {
    text-align: center;
    flex: 1 1 auto;
  }
  .links {
    text-align: right;
    flex: 1 1 auto;
  }
}

#nprogress {
  .bar {
    height: 6px;
    background-color: #F37C37;
    .peg {
      display: none;
    }
  }
  .spinner {
    display: none !important;
  }
}

</style>
